<template>
  <div class="table">
    <table>
      <thead>
        <tr class="row-header">
          <slot name="header">
            <!-- example markup -->
            <!-- <th>
            ROI(%) <span><font-awesome-icon icon="sort"/></span>
          </th> -->
            <!-- <th>
            Ranking <span><font-awesome-icon icon="sort"/></span>
          </th> -->
            <th v-for="title in header" :key="title">{{ title }}</th>
          </slot>
        </tr>
      </thead>
      <tbody>
        <!-- example markup -->
        <!-- <tr v-for="index in 6" :key="index">
          <td>26-Nov-2020</td>
          <td>Private Game</td>
          <td>E-mini S&p 500</td>
          <td>656.6%</td>
          <td>151</td>
        </tr> -->
        <slot> </slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  overflow-x: scroll;
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none; 

//   &::-webkit-scrollbar{
//       display: none;
//   }
}



table {
  width: 100%;
  border-collapse: collapse;

  th {
    background: $grayDark;
    letter-spacing: 0.1rem;
    padding: 0.75rem;
    color: $white;
    text-align: start;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .row-header {
    border: 1px solid transparent;
  }
  tr {
    border: 1px solid $gray;
    border-right: 1px solid $gray;
    border-bottom-right-radius: 50%;
  }

  td {
    padding: 0.75rem;
  }
}
</style>