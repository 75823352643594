<template>
  <Container>
    <div class="top-form">
      <div class="form-group search">
        <input
          type="text"
          v-model="search"
          placeholder="Press enter to search..."
          @keyup.enter="retrieveData"
        />
      </div>
      <div class="action">
        <base-button @click="exportDataHandler">Export Data</base-button>
      </div>
    </div>

    <div>
      <vue-good-table
        v-if="users.data && users.data.length"
        :columns="columns"
        :rows="users.data"
        :pagination-options="{
          enabled: false,
          pageLabel: 'page', // for 'pages' mode
        }"
        styleClass="vgt-table striped"
        @on-sort-change="onSortChange"
      >
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'action'"
            @click="viewUser(props.formattedRow.id)"
          >
            <span>
              <base-button mode="square">
                <font-awesome-icon icon="edit" />
              </base-button>
            </span>
          </span>

          <span v-if="props.column.field === 'status'">
            <span>
              {{ props.column.last_online | isOnline }}
              <font-awesome-icon
                :class="{
                  online: props.column.last_online,
                  offline: !props.column.last_online,
                }"
                icon="circle"
              />
            </span>
          </span>

          <span v-else>
            <span> {{ props.formattedRow[props.column.field] }}</span>
          </span>
        </template>
      </vue-good-table>
      <div class="pagination">
        <div class="pagination-perPage"> 
          <span>Show</span>
          <v-select class="select-row" v-model="perPage"  :options="[10,20,30]" placeholder="No of items per page" :clearable="false"></v-select>
          <span>Rows</span>
        </div>
        <div class="pagination-action">
          <base-button
            :disabled="!users.prev_page_url"
            @click="currentPage--"
            mode="dark admin-user"
            >Previous</base-button
          >
          <span class="number"> {{ currentPage }} </span>
          <base-button
            mode="dark admin-user"
            @click="currentPage++"
            :disabled="!users.next_page_url"
            >Next</base-button
          >
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import { eventBus } from "@/main.js";
import moment from "moment";
import { getUser, exportUser } from "@/services/adminAPI/users.js";
// import UserMobile from "./components/UserMobile";
import BaseTable from "@/components/BaseTable";
const UserMobile = () =>
  import(/* webpackPrefetch: true */ "./components/UserMobile");

export default {
  components: {
    UserMobile,
    BaseTable,
  },
  data() {
    return {
      windowHeight: window.innerWidth,
      entries: 10,
      users: "",


      search: "",
      currentPage: 1,
      perPage: 10,
      order: '',
      asc: 0,

      timeoutSearch: null,
      columns: [
        {
          label: "Id",
          field: "id",
          hidden: true,
          sortable: false,
          thClass: "table-row",
        },
        {
          label: "Name",
          field: "name",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Email",
          field: "email",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Contact No",
          field: "phone",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Category",
          field: "role.name",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Status",
          field: "status",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Last Activity",
          field: "last_online",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Action",
          field: "action",
          hidden: false,
          sortable: false,
          thClass: "table-row",
        },
      ],
    };
  },
  filters: {
    isOnline(val) {
      return val ? "Online" : "Offline";
    },
    lastOnline(date) {
      return date ? moment(date).format("DD MMM YYYY h:mm a") : "Not Available";
    },
  },
  methods: {
    viewUser(userId) {
      this.$router.push({
        name: "IndividualUsers",
        params: { userId: userId, user: this.getSpecificUser(userId)[0] },
      });
    },
    getSpecificUser(id) {
      return this.users.data.filter((data) => data.id == id);
    },
    async retrieveData() {
      eventBus.$emit("loader", true);
      const req = await getUser({
        page: this.currentPage,
        limit: this.perPage,
        query: this.search,
        order: this.order,
        asc: this.asc
      });
      this.users = req.data.response.users;
      eventBus.$emit("loader", false);
    },
    capitalize(string) {
      if (string === "user") {
        return "Player";
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    onSortChange(params) {

      if(params[0].field === 'role.name'){
        this.order = 'CATEGORY';
      }else{
        this.order = params[0].field.toUpperCase();
      }

      this.asc = params[0].type === 'asc' ? 1 : 0;
    },
    async exportDataHandler(){
      try {
        eventBus.$emit('loader',true);

        const request = await exportUser({
          q: this.search,
          order: this.order,
          asc: this.asc
        });

        console.log(request);
        
        await this.save(`users-${Date.now()}.xlsx`,request.data, 'octet/stream',false)

        eventBus.$emit('loader',false);

      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Oops!',
          text: error.response.data.userMessage
        })
      }
    },
    async save(name, data, type, isBinary) {
      console.log(data);
      if (isBinary) {
        var bytes = new Array(data.length);
        for (var i = 0; i < data.length; i++) {
            bytes[i] = data.charCodeAt(i);
        }
        data = new Uint8Array(bytes);
      }

      var blob = new Blob([data], {type: type});
      let objectURL = window.URL.createObjectURL(blob);
      let anchor = document.createElement('a');

      anchor.href = objectURL;
      anchor.download = name;
      anchor.click();

      URL.revokeObjectURL(objectURL);
    }
  },

  watch: {
    perPage(){
      this.currentPage = 1
      this.retrieveData()
    },
    currentPage(val) {
      if (val < 1) {
        this.currentPage = 1;
        return;
      }
      this.retrieveData();
    },
    search(val) {
      clearTimeout(this.timeoutSearch);
      setTimeout(() => {
        if (val === this.search) {
          this.retrieveData();
        }
      }, 300);
    },
  },
  computed: {
    getTableData() {
      return this.users.data;
    },
  },
  async mounted() {
    eventBus.$emit("loader", true);
    this.retrieveData();
    eventBus.$emit("loader", false);
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerWidth;
    });
  },
  destroyed() {
    window.removeEventListener("resize", () => {
      this.windowHeight = window.innerWidth;
    });
  },
};
</script>

<style lang="scss" scoped>
.top-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @include respond(tab-port) {
    flex-direction: column;
    gap: 1rem;
  }
  .form-group {
    flex-basis: 50%;
    input {
      margin: 0;
    }
  }

  .search {
    width: 50%;
    @include respond(tab-port) {
      width: 100%;
    }
  }
  .action {
    width: 48%;
    display: flex;
    justify-content: space-between;
    @include respond(tab-port) {
      width: 100%;
    }
  }
}
.online {
  color: green;
}
.offline {
  color: red;
}
.pagination {
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 480px){
    flex-direction: column;
    align-items: center;
    gap: .5rem;
  }
  &-perPage{
    display: flex;
    gap: .4rem;
    align-items: center;

    .select-row{
      width: 5rem;
    }
  }
  &-action {
    position: relative;
    .number {
      margin: auto 0.5rem;
    }
    .number::before {
      // content: '';
      // top: 0;
      // left: 46%;
      // position: absolute;
      // border-top: 1px solid #f0f0f0;
      // width: 3.7rem;
    }
  }
}
</style>
